import API from "@utils/request";
import { PAGE_SIZE } from "@config";
import store from "../store";
const ADRESS = "/api/burialuser/";

/**
* 获取逝者信息管理列表（分页）
* @author 四川创企
* @DateTime 2024/01/02
* @param {object} params
* @param {object} pages
*/
export async function getBurialUserList(params, pages) {
    try {
        return await API.get(ADRESS + "list", {
            params: {
                ...params,
                ...pages,
            },
            headers: { isLoading: true },
        });
    } catch (error) {
        return error;
    }
}


/**
* 远程搜索
* @author 四川创企
* @DateTime 2023/12/29
* @param {object} params
* @param {object} pages
*/
export async function buRemoteSelect(params, pages) {
    try {
        return await API.get(ADRESS + "remoteselect", {
            params: {
                ...params,
                ...pages,
            },
            headers: { isLoading: true },
        });
    } catch (error) {
        return error;
    }
}

/**
* 获取逝者信息管理详情
* @author 四川创企
* @DateTime 2024/01/02
* @param {number} id
*/
export async function getBurialUserInfo(id) {
    try {
        return await API.post(ADRESS + "info", {
            id: id,
        });
    } catch (error) {
        return error;
    }
}
/**
* 编辑逝者信息管理
* @author 四川创企
* @DateTime 2024/01/02
* @param {object} params
*/
export async function editBurialUser(params) {
    try {
        return await API.post(ADRESS + "edit", params);
    } catch (error) {
        return error;
    }
}

/**
* 新增逝者信息管理
* @author 四川创企
* @DateTime 2024/01/02
* @param {object} params
*/
export async function addBurialUser(params) {
    try {
        return await API.post(ADRESS + "add", params);
    } catch (error) {
        return error;
    }
}
/**
* 审核逝者信息管理
* @author 四川创企
* @DateTime 2024/01/02
* @param {object} params
*/
export async function examineBurialUser(params) {
    try {
        return await API.post(ADRESS + "examine", params);
    } catch (error) {
        return error;
    }
}
/**
* 刪除逝者信息管理
* @author 四川创企
* @DateTime 2024/01/02
* @param {number} id
*/
export async function deleteBurialUser(id) {
    try {
        return await API.post(ADRESS + "delete", {
            id: {
                id,
            },
        });
    } catch (error) {
        return error;
    }
}
/**
* 获取逝者信息管理键值对
* @author 四川创企
* @DateTime 2024/01/02
* @param {object} key
* @param {object} value
*/
export async function getBurialUserKeyVal(key, value, params = {}) {
    try {
        return await API.post(ADRESS + "getkeyval", {
            key: key,
            value: value,
            params: params
        });
    } catch (error) {
        return error;
    }
}


/**
* 冰柜逝者数据
* @author 四川创企
* @DateTime 2024/01/02
* @param {object} params
*/
export async function getBurialUserDepositData(params) {
    try {
        return await API.post(ADRESS + "freezer_burial", params);
    } catch (error) {
        return error;
    }
}

/**
* 获取未绑定墓穴的逝者 信息管理键值对
* @author 四川创企
* @DateTime 2024/01/02
* @param {object} key
* @param {object} value
*/
export async function getNotBindBurialUserKeyVal(key, value, params = {}) {
    try {
        return await API.post(ADRESS + "getnotbindkeyval", {
            key: key,
            value: value,
            params: params
        });
    } catch (error) {
        return error;
    }
}
<!-- 下拉远程搜索 -->
<template>
  <el-select v-model="optKey" :size="size" :multiple="multiple" :multiplelimit="multiplelimit" filterable remote reserve-keyword
    popper-class="more-next-box" :placeholder="placeholder" :remote-method="remoteMethod" :disabled="disabled"
    v-loadmore="loadMore" @change="changeselect" @focus="focusList" :clearable="clearable">
    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
    <div class="uploadTip" v-if="loadMoreLoading && loadNoMore">数据加载中......</div>
    <div class="uploadTip" v-if="!loadNoMore">--没有更多--</div>
  </el-select>
</template>
<script>
import { remoteSelect } from "@api/User";
import { buRemoteSelect } from "@api/BurialUser";

export default {
  name: 'remote-select',
  props: {
    // 选中的下标
    value: {
      type: [Number, String, Array],
      default: undefined
    },
    //操作的下标内容
    optName: {
      type: [Number, String, Array],
      default: undefined
    },
    // 获取键值对模型
    tperType: {
      type: String,
      default: "User"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: "请选择"
    },
    // 按钮大小
    size: {
      type: String,
      default: "small"
    },
    // 是否多选
    multiple: {
      type: Boolean,
      default: false
    },
    // 获取键值对模型
    multiplelimit: {
      type: String,
      default: "1"
    }
  },
  data() {
    return {
      form: {
        pageindex: 1,
        pagesize: 10,
      },
      options: [],
      loadMoreLoading: false,    // 翻页加载中参数
      optKey: undefined,     // 选中的key
      operateName: "",
      loadNoMore: false,          // 没有更多的状态
      allpage: 1,//总页数
    }
  },
  mounted() {
    // this.getList()
    // 选中的显示到第一个
    if (this.operateName) {
      if (!this.multiple) {
        // 单选
        this.options.push({ value: this.optKey, label: this.operateName })
      } else {
        // 多选
        // this.operateName.map((item) =>{
        //   this.options.push(item);
        // })
        
      }
    }

  },
  watch: {
    value: {
      handler(data) {
        this.optKey = data
      },
      immediate: true
    },
    optName: {
      handler(data) {
        this.operateName = data ? data : ""
      },
      immediate: true

    }
  },
  directives: {
    loadmore: {
      inserted(el, binding) {
        const SELECTWRAP_DOM = el.querySelector(
          ".el-select-dropdown .el-select-dropdown__wrap"
        );
        SELECTWRAP_DOM.addEventListener("scroll", function () {
          const condition = this.scrollHeight - this.scrollTop <= this.clientHeight;
          if (condition) {
            binding.value();
          }
        });
      },
    },
  },
  methods: {
    async remoteMethod(query) {
      this.query_word = query
      this.form.pageindex = 1
      // if (query !== '') {
      this.getList()
      // }
    },
    loadMore() {
      if (this.loadNoMore && !this.loadMoreLoading && this.allpage > this.form.pageindex) {
        this.form.pageindex = this.form.pageindex + 1
        this.loadMoreLoading = true
        this.getList()
      }
    },
    async getList() {
      let pages = {
        pagesize: this.form.pagesize,
        pageindex: this.form.pageindex,
      };
      let params = {}
      if (this.query_word !== '') {
        params.query_word = this.query_word
      }
      if (this.form.pageindex == 1) {
        this.options = []
        // 选中的显示到第一个
        if (this.operateName) {
          if (this.options.length > 0) {
            const indexs = this.options.findIndex(item => item.value === this.optKey);
            if (indexs !== -1) {
              this.options.splice(indexs, 1);
            } else {
              this.options.push({ value: this.optKey, label: this.operateName })
            }
          }
        }
      }
      let data = [];
      if (this.tperType == "User") {
        // 用户查询
        let resdata = await remoteSelect(params, pages);
        data = resdata.data;
      } else if (this.tperType == "burialUser") {
        // 逝者查询
        let resdata = await buRemoteSelect(params, pages);
        data = resdata.data;
      }

      // 删除返回已经匹配上的内容
      //const index = data.data.findIndex(item => item.label === this.operateName && item.value === this.optKey);
      this.options = [...this.options, ...data.data];
      // console.log("options", this.options);
      // 还有更多
      this.allpage = Math.ceil(data.allcount / 10)
      if (data.allcount > this.options.length) {
        this.loadNoMore = true
      } else {
        this.loadNoMore = false
      }
      this.loadMoreLoading = false;
      this.$forceUpdate();
    },
    changeselect(val) {
      this.$emit('input', val)
      // 返回整条数据出去
      const index = this.options.findIndex(i => i.value === val)
      if (index !== -1) {
        this.$emit('inputAll', this.options[index])
      }
    },
    focusList() {
      this.form.pageindex = 1
      this.query_word = ""
      this.getList()
    },
  },

}

</script>
<style scoped>
.uploadTip {
  width: 100%;
  text-align: center;
  color: #bbbbbb;
}

::v-deep .el-scrollbar {
  transform-style: preserve-3d;

}
</style>